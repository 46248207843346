.product-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .product-category__card {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    transition: transform 0.3s;
  }
  
  .product-category__card:hover {
    transform: scale(1.05);
  }
  
  .product-category__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .product-category__name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .product-category__price {
    font-size: 14px;
    color: #888;
  }
  