.certification-section{
    align-items: center;
    gap: 30px;
    display: flex;
    justify-content: space-between;
    padding: 3rem 5rem;
   
    border: 1px solid #CDCDCD;
   
    border-radius: 10px;
background: linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%);
}
.content5-heading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content2-btn:hover{
   
    transition: 0.2s ease-in-out;
    transform: scale(1.1);
}
.content2-btn{

    color: #FFF;
    width: 237px;
    padding: 12px 64px;
    height: 56px;
    border: none;
    
    flex-shrink: 0;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 165% */
    text-transform: capitalize;
    border-radius: 10px;
    
background: linear-gradient(90deg, #B069E4 -57.21%, #FF5252 154.15%);
box-shadow: 4px 24px 60px 0px rgba(109, 141, 173, 0.25);
    transition: 0.2s ease-in-out;
}
@media screen and (max-width: 1500px){
    .content5-heading{
        font-size: 25px;
    }
    .content2-btn{
        max-width: 200px;
        max-height: 50px;
        font-size: 16px;
      
    }
    .certilogo img{
        max-width: 80px;
    }
    .certification-section{
        padding: 3rem 11rem;
        margin: 3rem 0rem;
    }

}
@media screen and (max-width: 1200px){
    .content5-heading{
        font-size: 20px;
    }
    .content2-btn{
        width: 100%;
        height: 40px;
        font-size: 10px;
        padding:5px 34px;
      
    }
    .certilogo img{
        max-width: 60px;
    }
    .certification-section{
        padding: 3rem 4rem;
        margin: 3rem 0rem;
    }


}

@media screen and (max-width: 947px){
  
    .certification-section{
        padding: 3rem 2rem;
        margin: 3rem 0rem;
    }



}
@media screen and (max-width: 600px){
    .certification-section{
        padding: 3rem 1rem;
        margin: 1rem 0rem;
    }
    .content5-heading{
        font-size: 16px;
    }


}