.services-submenu {
  width: 20rem;
  position: absolute;
  list-style: none;
  text-align: start;
  border-radius: 5px;
  background: #000000;
  margin-top: 10px;
  padding: 20px;
  transition: 0.4s ease-in-out;
}
.services-submenu.clicked {
  display: none;
}
/* .services-submenu li {
} */
.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;

  padding: 16px;
  color: #fff;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.submenu-item:hover {
  background-color: rgb(158, 158, 158);
  transition: 0.3s ease-in-out;
}
