.call-button {
    text-decoration: none;
    color: black;
    display: inline-block;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    text-decoration: underline;
  }
  
  .call-button--footer {
    color: white;
    background-color: black;
  }
  
  .call-button--header {
    color: red;
    background-color: white;
    font-size: 12px;
    padding: 5px;
    font-weight: 400;
    text-decoration: none;

  }
  
  .call-button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  