/* 
.our-brand-container {
    padding: 2rem;
    text-align: center;
    background-color: #f4f4f4;
  }
  
  .our-brand-container h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 600;
  }
  
  .brand-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    padding: 0 1rem; 
  }
  
  .brand-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden; 
    text-align: center;
    padding: 1rem;
  }
  
  .brand-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .brand-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 2px solid #eee; 
  }
  
  .brand-name {
    font-size: 1.2rem;
    padding: 1rem;
    color: #333;
    font-weight: 500;
  }
   */

   /* OurBrand.css */

.our-brand-container {
    padding: 2rem;
    text-align: center;
    background-color: #f4f4f4;
  }
  
  .our-brand-container h2 {
    margin-top: 8rem;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 600;
  }
  
  .brand-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 2.5rem;
    padding: 0 10rem;
  }
  
  .brand-card {
    /* background-color: #fff; */
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    text-align: center;
    padding: 1rem;
  }
  
  .brand-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .brand-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 2px solid #eee;
    border-radius: 10px;
  }
  
  .brand-name {
    font-size: 1.2rem;
    padding: 1rem;
    color: #333;
    font-weight: 500;
  }
  
  @media (max-width: 1200px) {
    .brand-grid {
      grid-template-columns: repeat(3, 1fr); 
      padding: 0 1rem;

    }
  }
  
  @media (max-width: 900px) {
    .brand-grid {
      grid-template-columns: repeat(2, 1fr); 
      padding: 0 1rem;

    }
  }
  
  @media (max-width: 600px) {
    .brand-grid {
      grid-template-columns: 1fr; 
      padding: 0 1rem;

    }
  }
  