.oil-banner4 {
  margin-top: 10rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 30vh;
  flex-shrink: 0;
  border-radius: 10px;

  background: url("../../assets/services-bg/4.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay4 {
  border-radius: 10px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  z-index: 0;
}
.oil-title4 {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
}
.services-box4 {
  width: 335px;
  height: 128px;
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.12);
  text-align: center;
  margin: 1.5rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-box4:hover {
  font-size: 17px;
  border: 1px solid black;

  transition: 0.5s ease;
}
@media screen and (max-width: 947px) {
  .oil-banner4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  .oil-banner4 {
    font-size: 10px;
  }
}
