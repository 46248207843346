.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 1000;
  }
  
  .scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: black;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .scroll-button:hover {
    background-color: #f6f7f7;
    color: black;
  }
  
  .scroll-button svg {
    width: 20px;
    height: 20px;
  }
  