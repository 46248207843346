.cart-container {
  font-family: "Nunito", sans-serif;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.empty-cart-text {
  text-align: center;
  padding: 2rem;
  margin-top: 10rem;
}

.empty-cart-text h4 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.empty-cart-text a {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.empty-cart-text a:hover {
  text-decoration: underline;
}

h2 {
  font-size: 2rem;
  margin-top: 2rem;
}
.cart_item_container {
  display: flex;
  width: 100%;
  gap: 2rem;
}
.cart-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-details {
  flex: 1;
  margin-left: 1rem;
}

.cart-item-details h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
}

.quantity-button:hover {
  background-color: #0056b3;
}

.remove-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #c82333;
}

.item-price {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.cart-summary {
  width: 50%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.cart-summary h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.cart-summary span {
  font-weight: bold;
}

.store-selection {
  margin-top: 1rem;
}

.store-selection h3 {
  font-size: 1.25rem;
}

.address-section {
  margin-top: 2rem;
}

.add-address-button,
.save-address-button,
.order-now-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.add-address-button:hover,
.save-address-button:hover,
.order-now-button:hover {
  background-color: #0056b3;
}

.address-form {
  margin-top: 1rem;
}

.address-form input,
.address-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.address-card {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.address-card p {
  margin: 0.5rem 0;
}

@media (max-width: 768px) {
  .cart-item {
    /* flex-direction: column; */
    align-items: flex-start;
  }
  .cart_item_container {
    flex-direction: column;
  }

  .cart-item-image {
    width: 80px;
    height: 80px;
  }

  .cart-item-details {
    margin-left: 0;
  }

  .quantity-control {
    flex-direction: column;
  }

  .quantity-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .cart-summary {
    width: 100%;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }

  .cart-item-image {
    width: 60px;
    height: 60px;
  }

  .cart-summary h3 {
    font-size: 1rem;
  }

  .quantity-button {
    padding: 0.4rem;
  }

  .address-form input,
  .address-form textarea {
    padding: 0.4rem;
  }
}
