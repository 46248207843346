/* .oil-banner7 {
  margin-top: 10rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 30vh;
  flex-shrink: 0;
  border-radius: 10px;

  background: url("../../assets/aboutus/bg-aboutus.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay7 {
  border-radius: 10px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;

  z-index: 0;
}
.oil-title7 {
  z-index: 1;
}
.about-us-heading {
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;

  text-transform: capitalize;
  background: linear-gradient(90deg, #fd00dc -25.78%, #00fff0 190.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-us-decs {
  width: 100%;
  color: #000;
  font-family: "Open Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;

  text-transform: capitalize;
  margin: 2rem 0;
}

@media screen and (max-width: 1100px) {
  .about-us-decs {
    font-size: 20px;
  }
}
@media screen and (max-width: 945px) {
  .about-us-decs {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
} */



.about-us-container {
  padding: 2rem;
  margin-top: 5rem;
  font-family: 'Open Sans', sans-serif;
}

.oil-banner7 {
  margin-top: 8rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 40vh;
  border-radius: 10px;
  /* background: url("../../assets/background/about-bg.jpeg"); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.overlay7 {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.oil-title7 {
  text-align: center;
  padding: 0 20px;
  z-index: 1;
  animation: fadeInDown 2s ease-in-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
 .aboutus_container{
  display: flex;
 }
.about-us-heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
}

.highlighted-text {
  color: #00d2d3;
  font-weight: 700;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgba(0, 210, 211, 0.7), 0 0 20px rgba(0, 210, 211, 0.4);
  }
  to {
    text-shadow: 0 0 20px rgba(0, 210, 211, 0.9), 0 0 30px rgba(0, 210, 211, 0.6);
  }
}

.about-us-content {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
}

.about-us-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-us-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-decs {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin: 2rem 0;
  padding: 0 2rem;
  text-align: justify;
  line-height: 1.6;
}

@media screen and (max-width: 1100px) {
  .about-us-decs {
    font-size: 18px;
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 945px) {
  .about-us-decs {
    font-size: 16px;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 767px) {
  .about-us-content {
    flex-direction: column;
  }

  .about-us-image, .about-us-text {
    width: 100%;
  }
}

.carousel-img-wrapper {
  height: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel-img {
  height:500px;
  width: auto; 
}

.breadcrumb {
  margin-bottom: 2rem;
}
