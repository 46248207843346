.banner8 {
  margin-top: 10rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 30vh;
  flex-shrink: 0;
  border-radius: 10px;
  background: url("../../assets/contactus/ContactUs.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
}
.overlay8 {
  border-radius: 10px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  z-index: 0;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.title8 {
  z-index: 1;
}
.contact-heading-section {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
.contact-card-details{
  color: #000;
  font-weight: 700;
}
.contact-card-detail-whatsapp{
  color: #000;
}
.contact-us-heading {
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  background: linear-gradient(90deg, #fd00dc -25.78%, #00fff0 190.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-heading {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  text-transform: capitalize;
}
.contact-heading-desc {
  color: #d84d4d;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  text-transform: capitalize;
}

.contact-card {
  border-radius: 21px;
  border: 1px solid #d1d1d1;
  background: #fcfcfc;
  width: 335px;
  height: 296px;
  padding: 2.5rem 2.5rem;
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-card-title,
.contact-card-detail {
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  text-transform: capitalize;
}

.contact-card-detail {
  text-decoration-line: underline;
}
.contact-card-title-desc {
  color: #616161;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
}
.contact-card-icon {
  width: 70px;
  height: 70px;
  border-radius: 21px;
  border: 1px solid #e4e4e4;
  background: #fff;
  backdrop-filter: blur(7.5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1100px) {
}
@media screen and (max-width: 945px) {
  .contact-heading {
    font-size: 30px;
  }
  .contact-heading-desc {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .contact-heading {
    font-size: 25px;
  }
  .contact-heading-desc {
    font-size: 16px;
  }
}
